@import '@sodra/bongo-ui/default.css';

.bui {
  --hue: 332;
  --saturation: 59%;
  --lightness: 55%;
}

html,
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background: var(--surface);
  color: var(--on-surface);
}

* {
  box-sizing: border-box;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
